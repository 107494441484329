<template>
  <v-sheet
    v-bind="$attrs"
    class="mx-auto fullwidth d-flex align-center justify-center"
    elevation="0"
    max-width="100%"
  >
    <v-slide-group
      v-if="categories.length > 0"
      class="pa-4"
      v-model="model"
      :dark="dark"
      active-class="success"
      show-arrows
    >
      <v-slide-item v-for="category in categories" :key="category.id">
        <v-card
          class="ma-4 transparent pt-2 rounded-10"
          min-height="160"
          @click="$emit('selected', category)"
          elevation="0"
          width="150"
        >
          <div class="d-flex align-center">
            <v-avatar
              width="120"
              height="130"
              color="secondary"
              class="mx-auto rounded-5"
            >
              <v-img
                :lazy-src="settings.loader"
                contain
                height="130"
                :src="category.icon"
              ></v-img>
            </v-avatar>
          </div>
          <div class="light--text fw-600 text-center">
            {{ category.title }}
          </div>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <v-sheet
      height="200"
      class="primary fullwidth d-flex align-center justify-center"
      v-else
    >
      <span class="light--text fs-20">No category found</span>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'CategorySliders',
  props: {
    value: { type: [Object, String] },
    categories: { type: Array, default: () => [] },
    dark: { type: Boolean, default: false }
  },
  watch: {
    value: {
      handler: function (val) {
        this.model = val
      },
      deep: true,
      immediate: true
    },
    model: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  },
  data: () => ({
    model: null
  })
}
</script>

<style></style>

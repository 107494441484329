<template>
  <div class="products rounded-10">
    <v-card flat class="rounded-10">
      <v-row dense class="px-0" align="center" justify="center">
        <CategorySliders
          class="primary"
          dark
          @selected="set_selectedcategory"
          :categories="mainCategories"
        ></CategorySliders>
      </v-row>
      <v-card-title>
        <v-row dense align="center" class="mt-5">
          <v-col cols="12" md="2" lg="2" sm="5">
            <div class="align-center d-flex justify-start">
              <v-btn
                text
                color="primary"
                depressed
                class="text-none"
                @click="
                  $router.push({
                    name: 'manage-categories',
                    query: { redirect_uri: $route.fullPath }
                  })
                "
                v-if="canAddProduct"
              >
                <v-icon left color="primary">mdi-cog</v-icon>
                <span class="fw-700">Manage Categories</span>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" lg="8" md="10" sm="7">
            <div class="text-center fw-500 fs-30 primary--text">
              Types of Graphics
            </div>
          </v-col>
          <v-col cols="12" md="2" lg="2" sm="5">
            <div class="d-flex align-center justify-end">
              <v-btn
                color="primary"
                depressed
                class="text-none"
                @click="uploadNewProduct"
                v-if="canAddProduct"
              >
                <v-icon left color="white">mdi-plus-circle-outline</v-icon>
                <span class="fw-700">Add New</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-md-5 pa-sm-1 rounded-5">
        <v-sheet
          class="mx-auto fullwidth d-flex align-center justify-center"
          elevation="0"
          color="#F1EFEF"
          v-if="subCategories.length"
        >
          <v-slide-group
            style="justify-content: center !important"
            class="pa-md-4 pa-sm-1 offers-group-template d-flex"
            show-arrows
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
          >
            <v-slide-item
              style="justify-content: center; max-width: 200px"
              v-for="subcategory in subCategories"
              :key="subcategory.id"
            >
              <v-card
                class="ma-4 pt-2"
                height="180"
                color="#dedede"
                @click="gotoSubCategory(subcategory)"
                elevation="0"
                width="150"
              >
                <div class="d-flex align-center">
                  <v-img
                    :lazy-src="settings.loader"
                    contain
                    height="140"
                    :src="subcategory.icon"
                  ></v-img>
                </div>
                <div class="primary--text mt-2 fw-600 text-center">
                  {{ subcategory.title }}
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
        <v-sheet
          height="180"
          class="mx-auto d-flex fullwidth"
          elevation="0"
          color="#F1EFEF"
          v-else
        >
          <Empty
            class="align-self-center mx-auto"
            icon="mdi-image-off"
            :headline="
              activeCategory
                ? `No type of graphics found for ${activeCategory.title}`
                : `No type of graphics found`
            "
          ></Empty>
        </v-sheet>
        <div class="my-2 pa-1" v-if="loading_items">
          <v-progress-linear :height="10" color="primary" indeterminate />
        </div>
        <v-row dense align="center" justify="space-between" class="my-3">
          <v-btn
            link
            depressed
            text
            class="text-none primary--text fw-700 fs-25"
            @click="$router.push({ name: 'designs' })"
          >
            All Design
          </v-btn>
          <v-autocomplete
            v-model="searchModel"
            :items="searchResults"
            :loading="isSearchLoading"
            :search-input.sync="searchKeyword"
            color="primary"
            class="col-md-3 col-sm-12"
            hide-no-data
            hide-selected
            dense
            hide-details="auto"
            clearable
            outlined
            item-text="title"
            item-value="slug"
            placeholder="Search categories"
            prepend-inner-icon="mdi-magnify"
            return-object
          ></v-autocomplete>
        </v-row>
        <v-sheet color="#f1efef" class="pa-md-5 pa-sm-1 rounded-5">
          <v-row dense align="stretch" justify="start" v-if="items.length">
            <v-col
              cols="12"
              md="2"
              sm="6"
              xs="12"
              v-for="item in items"
              :key="item.id"
            >
              <ProductCard
                class="ma-2"
                :has-title="false"
                :product="item"
                @viewProduct="viewProduct(item)"
                @addToLikes="handleLiking(item)"
                @deleteProduct="confirmDeleteProduct(item)"
                @editProduct="editProduct(item)"
                @shareProduct="shareProduct(item)"
              />
            </v-col>
            <v-col cols="12" class="my-2 pa-1" v-if="loading_more_items">
              <v-progress-linear :height="10" color="primary" indeterminate />
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-btn
                class="text-none primary--text"
                v-if="has_more_items"
                :loading="loading_more_items"
                :disabled="loading_more_items"
                @click="loadMoreItems"
                depressed
                text
              >
                Load More
              </v-btn>
            </v-col>
          </v-row>
          <Empty v-else headline="No items found"></Empty>
        </v-sheet>
      </v-card-text>
    </v-card>
    <ProductViewDialog
      v-model="product_view_dialog"
      :product="activeProduct"
      @item-liked="itemLiked"
    ></ProductViewDialog>

    <DeleteDialog
      v-model="product_delete_dialog"
      title="Delete Product"
      text-content="Are you sure you want to delete this product? This will also delete all pending orders for this product"
      @delete="deleteProduct(delete_callback)"
    ></DeleteDialog>
  </div>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import ProductViewDialog from '../components/ProductViewDialog.vue'
import { offers_mixin } from '../mixin'
import ProductCard from '../components/ProductCard.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CategorySliders from '../components/CategorySliders.vue'
export default {
  name: 'Products',
  components: {
    DeleteDialog,
    ProductCard,
    ProductViewDialog,
    CategorySliders
  },
  mixins: [fetch_services, offers_mixin],
  data: () => ({
    templates: [],
    template_group: '',
    filters: {
      category: 'all'
    },
    searchResults: [],
    isSearchLoading: false,
    searchModel: null,
    searchKeyword: null
  }),
  computed: {
    ...mapGetters('cart', {
      activeCategory: 'selectedcategory',
      mainCategories: 'categories',
      subCategories: 'categorySubs'
    })
  },
  created() {
    this.$store.dispatch('cart/getCategories')
    this.loadLatestProducts()
  },
  mounted() {
    this.set_selectedcategory(
      this.mainCategories.length ? this.mainCategories[0] : null
    )
  },
  watch: {
    searchModel: {
      handler: function (val) {
        if (val) {
          this.$router.push({
            name: 'offers-by-category',
            params: { id: val.slug }
          })
        }
      },
      immediate: true,
      deep: true
    },
    searchKeyword(val) {
      if (this.searchResults.length > 0) return
      if (this.isSearchLoading) return

      this.isSearchLoading = true
      request
        .get(`api/categories/search?keyword=${val.trim()}`)
        .then(({ data }) => {
          this.searchResults = data
        })
        .finally(() => (this.isSearchLoading = false))
    }
  },
  methods: {
    ...mapMutations('cart', ['set_selectedcategory']),
    loadLatestProducts() {
      this.loadItems(`api/products/latest`, true, null, null)
    },
    singleProduct() {
      this.$router.push({ name: 'template_design' })
    },
    uploadNewProduct() {
      this.$router.push({ name: 'product_builder' })
    },
    emit_action(action) {
      this.$emit(action, this.item)
    },
    delete_callback(data) {
      this.removeItem(data.id, () => {
        this.appSnackbar('Product deleted')
      })
    },
    handleLiking(item) {
      request.post(`api/likes/${item.id}`).then(({ data }) => {
        this.itemLiked(data)
        this.appSnackbar(data.message)
      })
    },
    itemLiked(item) {
      let index = this.items.findIndex(
        (pro) => parseInt(pro.id) === parseInt(item.id)
      )
      if (~index) {
        this.items[index].is_liked = item.is_liked
        this.items[index].likes_count = item.likes_count
      }
    },
    gotoSubCategory(subcategory) {
      this.$router.push({
        name: 'offers-by-subcategory',
        params: { id: subcategory.slug }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.light-grey {
  background-color: #f1efef;
}
.offers-group-template {
  .v-slide-group__content {
    display: flex;
    justify-content: center !important;
  }
}
.offers-search-templates {
  input::placeholder {
    padding-left: 15px !important;
    color: #223a6b !important;
    font-weight: 700;
  }
  i {
    color: #223a6b !important;
  }
}

.add-new-product-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media all and (max-width: 960px) {
  .add-new-product-button {
    justify-content: center;
  }
}
</style>

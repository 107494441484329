<template>
  <div class="template-product-section">
    <v-dialog v-model="order_form" persistent max-width="60%">
      <v-card>
        <v-card-title
          style="background: #223a6b; padding-top: 10px; padding-bottom: 10px"
        >
          <span style="color: white; font-size: 18px">Social Post Order Form</span>
          <v-spacer />
          <v-icon @click="order_form = !order_form" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="5" sm="12">
              <img width="100%" height="100%" :src="image_product" alt="" />
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="12"
              class="justify-center"
              style="margin-top: 25px !important; padding: 0px 20px !important"
            >
              <label class="order-input-label">What is your website URL?</label>
              <v-text-field
                v-model="website_name"
                placeholder="Your website"
                filled
                dense
              ></v-text-field>
              <label class="order-input-label">Upload your image</label>
              <v-file-input
                filled
                placeholder="Select file"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
              ></v-file-input>
              <label for="instruction" class="order-input-label">Do you have any Special Instructions?</label>
              <v-textarea
                filled
                name="instruction"
                placeholder="Instructions here"
              ></v-textarea>
              <div class="d-flex justify-center">
                <v-btn depressed color="#223A6B" width="200px" height="40px">
                  <span style="color: white">Confirm Order</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="popup_image" width="700">
      <v-card>
        <v-card-text>
          <img width="100%" height="100%" :src="image_product" alt="" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card flat>
      <v-card-title style="padding-top: 0 !important">
        <v-tabs class="tabs" grow :value="active_tab">
          <v-tab
            class="text-none"
            style="color: #aeaeae; font-weight: 700; font-size: 17px"
            v-for="(tab, index) in tabs"
            :key="index"
            @click="changeTab(tab)"
            active-class="offers-active-class"
          >
            <div>
              {{ tab.name }}
            </div>
          </v-tab>
        </v-tabs>
      </v-card-title>
    </v-card>
    <v-card flat height="flat">
      <v-card-text style="padding: 20px 35px 10px 40px">
        <div class="d-flex template-product-title">Social Media Post</div>
        <v-row style="margin: 5px; background: #f1efef">
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            class="justify-center text-center"
          >
            <div
              style="
                font-size: 23px;
                color: #223a6b;
                font-weight: 600;
                padding: 15px 0;
              "
            >
              ${{ price }}
            </div>
            <div style="padding-bottom: 10px">
              <v-img
                width="225px"
                @click="imageShow"
                style="margin: auto"
                :src="image_product"
              />
            </div>
            <div class="row">
              <v-col cols="12" md="6" lg="6" sm="12" class="text-right">
                <v-btn tile color="white" width="100px" height="40px">
                  <span style="#223A6B">Like</span>
                  <v-icon right size="20px"> mdi-heart </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12" class="text-left">
                <v-btn
                  tile
                  color="white"
                  width="100px"
                  @click="order_form = !order_form"
                  height="40px"
                >
                  <span style="color: #223a6b">Order</span>
                  <v-icon right size="25px"> mdi-play </v-icon>
                </v-btn>
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" lg="8" md="8" sm="12" class="align-self-center">
            <div class="row">
              <v-col
                v-for="img in sub_images"
                :key="img"
                cols="12"
                md="3"
                lg="3"
                sm="6"
              >
                <v-img
                  :lazy-src="settings.loader"
                  width="80%"
                  :src="img.image"
                ></v-img>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Products from './tabs/Products.vue'
import OrderProduct from './components/OrderDialog.vue'
import Orders from './tabs/Orders.vue'
import Likes from './tabs/Likes'
import IncomingOrders from './tabs/IncomingOrders'
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'

export default {
  name: 'TemplateDesign',
  components: { IncomingOrders, Likes, Orders, Products, OrderProduct },
  data() {
    return {
      sub_images: [
        {
          image:
            'https://us.123rf.com/450wm/koblizeek/koblizeek1902/koblizeek190200055/125337077-no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-.jpg?ver=6'
        },
        {
          image:
            'https://us.123rf.com/450wm/koblizeek/koblizeek1902/koblizeek190200055/125337077-no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-.jpg?ver=6'
        },
        {
          image:
            'https://us.123rf.com/450wm/koblizeek/koblizeek1902/koblizeek190200055/125337077-no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-.jpg?ver=6'
        },
        {
          image:
            'https://us.123rf.com/450wm/koblizeek/koblizeek1902/koblizeek190200055/125337077-no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-.jpg?ver=6'
        }
      ],

      active_tab: 0,
      type: 'products',
      popup_image: false,
      image_product:
        'https://api.bizzooka.com/api2/attachments/thumb~/5x5i92c6csux4g6795moon6gb/programmer-thumbnail.jpg',
      price: '8.00',
      add_product: true,
      order_form: false,
      id: null,
      assigned_members: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    tabs() {
      let tabs = [
        { id: 0, name: 'Offers', type: 'products' },
        { id: 1, name: 'Likes', type: 'likes' },
        { id: 2, name: 'My Orders', type: 'orders' }
      ]
      if (this.can_manage_incoming_order) {
        tabs.push({ id: 3, name: 'Incoming Orders', type: 'incoming_orders' })
      }
      return tabs
    },
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        { text: 'Offers', disabled: true, route: { path: `/dashboard/offers` } }
      ]
    },
    allowed_members() {
      return this.assigned_members.map((i) => {
        return i.id
      })
    },
    can_manage_incoming_order() {
      return (
        this.user.is_super_admin || this.allowed_members.includes(this.user.id)
      )
    }
  },
  created() {
    this.getHandler()
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
    if (this.$route.params.type) {
      this.type = this.$route.params.type.toLowerCase()
      let index = this.tabs.findIndex(
        (item) => item.type.toLowerCase() === this.type
      )
      this.active_tab = ~index ? index : 0
    }
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    }
  },
  methods: {
    imageShow() {
      this.popup_image = true
    },
    changeTab(tab) {
      this.type = tab.type
      this.$router
        .push({
          name: 'offers',
          params: {
            id: this.id,
            type: this.type
          }
        })
        .catch(() => {})
    },
    orderProduct() {},
    getHandler() {
      request
        .get(`api/companies/${this.user.company_id}/order-handlers`)
        .then(({ data }) => {
          this.assigned_members = data
        })
    }
  }
}
</script>
<style>
.tabs .v-tabs-slider-wrapper {
  display: none !important;
}
.template-product-section {
  padding: 10px;
  height: 100%;
}
.order-input-label {
  color: #223a6b;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px !important;
}
.template-product-title {
  font-weight: 500;
  font-size: 28px;
  color: #223a6b;
  margin-bottom: 30px;
}
.template-product {
  background: #f1efef;
  width: 100%;
  display: flex;
}
</style>
<style scoped lang="scss">
.offers-active-class {
  color: #223a6b !important;
}
@include pagePadding('.offers');
</style>

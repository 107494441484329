<template>
  <div class="orders">
    <v-card min-height="500" flat>
      <v-card-title>
        <v-row dense class="pa-1" align="center" justify="space-between">
          <BoardLinks no-incoming-orders class="ma-1"></BoardLinks>
          <v-btn-toggle tile dense v-model="filter.status" mandatory>
            <template v-for="(status, index) in order_statuses">
              <v-btn
                :key="index"
                class="text-none"
                elevation="1"
                color="white"
                style="
                  padding: 15px 15px !important;
                  border: none !important;
                  margin-right: 3px;
                "
                active-class="active-button"
                :value="status.value"
              >
                <span style="color: #223a6b; font-weight: 700; font-size: 17px">
                  {{ status.title | ucwords }}
                </span>
                <v-chip small outlined class="ml-2" v-if="status.counts > 0">
                  {{ status.counts }}
                </v-chip>
              </v-btn>
            </template>
          </v-btn-toggle>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            min-width="500px"
            :nudge-width="200"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                elevation="1"
                color="white"
                class="text-none ma-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  left
                  style="padding-right: 5px"
                  size="25px"
                  color="#223A6B"
                >
                  mdi-account-cog-outline
                </v-icon>
                <span style="color: #223a6b; font-size: 16px">Settings </span>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span style="color: #223a6b; font-weight: 500; font-size: 18px">
                  Manage Order Handler
                </span>
                <v-spacer />
                <v-menu
                  top
                  class="fullwidth"
                  close-on-content-click
                  :nudge-width="150"
                  style="min-width: unset !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="text-none fs-15"
                      color="primary"
                      height="35px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Add Member
                    </v-btn>
                  </template>

                  <v-list dense :max-height="400" style="overflow-y: auto">
                    <v-list-item
                      v-for="item in filtered_users"
                      :key="item.id"
                      @click="addHandler(item)"
                      style="color: #223a6b"
                    >
                      <v-list-item-avatar>
                        <v-img
                          :lazy-src="settings.loader"
                          :src="item.image_url"
                        />
                      </v-list-item-avatar>
                      <v-list-item-title style="padding-left: 10px">
                        {{ item.fullname | ucwords }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-list dense>
                <template v-if="assigned_members.length > 0">
                  <v-list-item
                    v-for="user in assigned_members"
                    :key="user.id"
                    style="
                      color: #223a6b !important;
                      padding-bottom: 5px !important;
                    "
                  >
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="user.image_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-title
                      style="padding-left: 10px; font-size: 15px"
                    >
                      {{ user.fullname | ucwords }}
                    </v-list-item-title>
                    <v-list-item-action style="padding-right: 10px">
                      <v-icon
                        @click="removeHandler(user)"
                        size="30px"
                        color="#223A6B"
                      >
                        mdi-close-circle-outline
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <empty v-else headline="No assigned members yet." />
                <v-divider style="margin-top: 15px"> </v-divider>
                <v-list-item style="padding-left: 30px">
                  <v-list-item-action>
                    <v-switch
                      v-model="auto_assign"
                      @change="updateAutoAssign"
                      color="primary"
                    >
                    </v-switch>
                  </v-list-item-action>
                  <v-list-item-title style="color: #223a6b; font-weight: 500">
                    Enable auto assign incoming orders to allowed members
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="ma-2 primary--text fw-700 fs-20">
        Incoming Orders
      </v-card-subtitle>
      <v-card-text>
        <v-row dense style="margin-top: 15px">
          <v-col md="12">
            <v-row v-if="orders.length > 0">
              <v-col
                md="12"
                sm="12"
                v-for="(item, index) in orders"
                :key="index"
              >
                <IncomingList
                  :order="item"
                  :allowed-users="assigned_members"
                  @cancelOrder="confirmCancelOrder(item)"
                  @deleteOrder="confirmDeleteOrder(item)"
                  @completedOrder="confirmCompletedOrder(item)"
                  @assign-to="handleAssigned"
                  @unassigned-from="handleUnassigned"
                >
                  <!-- <template v-slot:extra_manage_list>
                    <v-list-item
                      @click.stop="createOrderInvoice(item)"
                      v-if="!item.invoice"
                    >
                      <v-list-item-title>
                        <v-icon left color="#223A6B">mdi-cash-register </v-icon>
                        Create Order Invoice
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.stop="updateOrderInvoice(item)" v-else>
                      <v-list-item-title>
                        <v-icon left color="#223A6B">mdi-cash-register </v-icon>
                        Update Order Invoice
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click.stop="viewOrderInvoice(item)"
                      v-if="item.invoice"
                    >
                      <v-list-item-title>
                        <v-icon left color="primary">mdi-cash-register </v-icon>
                        View Order Invoice
                      </v-list-item-title>
                    </v-list-item>
                  </template> -->
                </IncomingList>
              </v-col>
              <v-col md="12" v-if="next_page_url">
                <v-btn
                  @click="getMoreOrders"
                  block
                  depressed
                  class="text-none my-2"
                  :loading="loading"
                >
                  View More
                </v-btn>
              </v-col>
              <v-progress-linear
                v-show="loading"
                indeterminate
                class="my-1"
                color="primary"
              >
              </v-progress-linear>
            </v-row>
            <empty
              v-else
              icon="mdi-cart-off"
              :headline="filter_result_message"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <DeleteDialog
      :open-dialog.sync="remove_handler_dialog"
      title="Confirmation required!"
      text-content="Are you sure you want to remove this user from order handler? This will also remove user from its assigned orders."
      @delete="confirmedRemoveHandler"
    />
    <DeleteDialog
      :open-dialog.sync="confirm_action_dialog"
      title="Confirmation required!"
      :text-content="confirm_text"
      @delete="callback(confirm_action)"
    />
    <ViewInvoice v-model="view_invoice_dialog" :invoice="view_invoice_item">
    </ViewInvoice>
  </div>
</template>

<script>
import { offers_mixin } from '../mixin'
import request from '@/services/axios_instance'
import IncomingList from '../components/IncomingList.vue'
import { mapGetters, mapMutations } from 'vuex'
import _diffBy from 'lodash/differenceBy'
import DeleteDialog from '@/common/DeleteDialog.vue'
import ViewInvoice from '@/modules/Invoice/components/ViewInvoice/ViewInvoice.vue'
import BoardLinks from '../components/BoardLinks.vue'
export default {
  name: 'IncomingOrders',
  mixins: [offers_mixin],
  components: {
    DeleteDialog,
    IncomingList,
    ViewInvoice,
    BoardLinks
  },
  data: () => ({
    view_invoice_dialog: false,
    view_invoice_item: null,
    menu: false,
    orders: [],
    order_statuses: [
      { title: 'All', counts: 0, value: 'all' },
      { title: 'Ongoing', counts: 0, value: 'ongoing' },
      { title: 'Cancelled', counts: 0, value: 'cancelled' },
      { title: 'Completed', counts: 0, value: 'completed' }
    ],
    order_categories: [],
    next_page_url: null,
    loading: false,
    filter: {
      category: 0,
      status: 'all'
    },
    auto_assign: false,
    assigned_members: [],
    executing: false,
    remove_handler_dialog: false,
    to_be_removed_user: null,
    active_order: null,
    confirm_text: '',
    confirm_action: null,
    confirm_status: null,
    confirm_action_dialog: false
  }),
  created() {
    // this.getOrderCategories();
    this.getPaidOrders()
    this.getHandler()
    if (this.user && this.user.company.settings)
      this.auto_assign = this.user.company.settings.hasOwnProperty(
        'auto_assign_order'
      )
        ? this.user.company.settings.auto_assign_order
        : false
  },
  computed: {
    ...mapGetters(['users', 'user']),
    filter_result_message() {
      if (this.filter.status === 'all') return 'No incoming orders yet!'
      return `No incoming orders with ${this.filter.status.replace(
        '_',
        ' '
      )} status!`
    },
    filtered_users() {
      return _diffBy(
        this.users.filter((i) => !i.is_client),
        this.assigned_members,
        'id'
      )
    }
  },
  watch: {
    'filter.status': {
      handler(val) {
        this.getPaidOrders()
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['set_user_company']),
    updateAutoAssign() {
      this.updateSetting({ key: 'auto_assign_order', value: this.auto_assign })
    },
    updateSetting(payload) {
      request
        .post(`api/company/${this.user.company_id}/settings-by-key`, payload)
        .then(({ data }) => {
          this.set_user_company(data)
          this.appSnackbar('Settings updated!')
        })
    },
    getOrderCategories() {
      request.get(`api/orders/categories`).then(({ data }) => {
        this.order_categories.push(...data)
      })
    },
    getPaidOrders() {
      this.loading = true
      request
        .get(`api/orders/incoming-orders?status=${this.filter.status}&paid=1`)
        .then(({ data }) => {
          this.orders = data.data
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading = false))

      if (!this.orders) {
        this.orders = [{ counts: '1' }]
      }
    },
    getMoreOrders() {
      this.loading = true
      if (this.next_page_url) {
        request
          .get(this.next_page_url)
          .then(({ data }) => {
            this.orders.push(...data.data)
            this.next_page_url = data.next_page_url
          })
          .finally(() => (this.loading = false))
      }
    },
    getHandler() {
      this.executing = true
      request
        .get(`api/companies/${this.user.company_id}/order-handlers`)
        .then(({ data }) => {
          this.assigned_members = data
        })
        .finally(() => (this.executing = false))
    },
    addHandler(user) {
      this.executing = true
      request
        .post(`api/companies/${this.user.company_id}/order-handlers`, {
          user_id: user.id
        })
        .then(({ data }) => {
          this.assigned_members.push(user)
          this.appSnackbar('A member is added to order handler!')
        })
        .finally(() => (this.executing = false))
    },
    removeHandler(user) {
      this.to_be_removed_user = user
      this.remove_handler_dialog = true
    },
    confirmedRemoveHandler() {
      this.executing = true
      request
        .delete(
          `api/companies/${this.user.company_id}/order-handlers/${this.to_be_removed_user.id}`
        )
        .then(({ data }) => {
          let index = this.assigned_members.findIndex(
            (i) => i.id === this.to_be_removed_user.id
          )
          if (~index) {
            this.assigned_members.splice(index, 1)
            this.appSnackbar('A member is removed from order handler!')
          }
        })
        .finally(() => {
          this.executing = false
          this.remove_handler_dialog = false
        })
    },
    handleAssigned(payload) {
      request
        .post(`api/orders/${payload.order_id}/order-handlers/add`, payload)
        .then(({ data }) => {
          let index = this.orders.findIndex((i) => i.id === payload.order_id)
          if (~index) {
            let order = this.orders[index]
            order.orderhandlers = data
            this.orders.splice(index, 1, order)
            this.appSnackbar('Successfully assigned member')
          }
        })
    },
    handleUnassigned(payload) {
      request
        .post(`api/orders/${payload.order_id}/order-handlers/remove`, payload)
        .then(({ data }) => {
          let index = this.orders.findIndex((i) => i.id === payload.order_id)
          if (~index) {
            let order = this.orders[index]
            order.orderhandlers = data
            this.orders.splice(index, 1, order)
            this.appSnackbar('Successfully unassigned member')
          }
        })
    },
    callback(action) {
      this[action]()
    },
    confirmCancelOrder(order) {
      this.active_order = order
      this.confirm_text = 'Are you sure you want to cancel this order?'
      this.confirm_action = 'handleChangeStatusOrder'
      this.confirm_status = 'cancelled'
      this.confirm_action_dialog = true
    },
    confirmDeleteOrder(order) {
      this.active_order = order
      this.confirm_text = 'Are you sure you want to delete this order?'
      this.confirm_action = 'handleDeleteOrder'
      this.confirm_action_dialog = true
    },
    confirmForApprovalOrder(order) {
      this.active_order = order
      this.confirm_text = 'Are you sure you want to set to ongoing this order?'
      this.confirm_action = 'handleChangeStatusOrder'
      this.confirm_status = 'ongoing'
      this.confirm_action_dialog = true
    },
    confirmCompletedOrder(order) {
      this.active_order = order
      this.confirm_text =
        'Are you sure you want to set this order to completed?'
      this.confirm_action = 'handleChangeStatusOrder'
      this.confirm_status = 'completed'
      this.confirm_action_dialog = true
    },
    handleChangeStatusOrder() {
      request
        .put(`api/orders/${this.active_order.id}/${this.confirm_status}`)
        .then(({ data }) => {
          let index = this.orders.findIndex((i) => i.id === data.id)
          if (~index) {
            this.orders.splice(index, 1, data)
            this.appSnackbar('Order successfully updated')
          }
        })
        .finally(() => {
          this.active_order = null
          this.confirm_action = null
          this.confirm_status = null
          this.confirm_action_dialog = false
        })
    },
    handleDeleteOrder() {
      request
        .delete(`api/orders/${this.active_order.id}/delete`)
        .then(({ data }) => {
          let index = this.orders.findIndex(
            (i) => i.id === this.active_order.id
          )
          if (~index) {
            this.orders.splice(index, 1)
            this.appSnackbar('Order successfully deleted')
          }
        })
        .finally(() => {
          this.active_order = null
          this.confirm_action = null
          this.confirm_status = null
          this.confirm_action_dialog = false
        })
    },
    createOrderInvoice(order) {
      this.$router.push({
        name: 'invoice-maker',
        query: { for_type: 'order', for_id: order.id }
      })
    },
    viewOrderInvoice(order) {
      this.view_invoice_item = order.invoice
      this.view_invoice_dialog = true
    },
    updateOrderInvoice(order) {
      this.$router.push({
        name: 'invoice-maker',
        params: { id: order.invoice.id },
        query: { for_type: 'order', for_id: order.id }
      })
    }
  }
}
</script>

<style scoped>
.active-button {
  background: #223a6b !important;
}
.active-button span {
  color: white !important;
}
</style>

import request from '@/services/axios_instance'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _ from 'lodash';

export default {
    components: {
        CustomDialog,
    },
    props: {
        value: { type: Boolean, default: false },
        invoice: Object
    },
    data: () => ({
        open: false,
        loading: false,
        parseTemplate: '',
        item: null
    }),
    watch: {
        value(val) {
            this.open = val;
        },
        open(val) {
            this.$emit('input', val)
        },
        invoice: {
            handler: function (val) {
                if (val) {
                    this.parseTemplate = window.location + '/invoice/' + val.id
                }
                // this.item = _.cloneDeep(val)
                // if (val) {
                //     this.getParseHtml()
                // }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.$event.$on('btnloading_off', status => (this.btnloading = status))
    },

    computed: {
        user() {
            return this.$store.getters.user
        },
        can_pay() {
            return this.item.billed_to === this.user.id
        },
        property() {
            if (!this.item.props) {
                return { send_email: false, template: null }
            }
            return typeof this.item.props === 'string' ? JSON.parse(this.item.props) : this.item.props
        },
        style() {
            return `min-height:470px; width: 100%;border:none;`
        },
        dialogTitle() {
            return this.item ? this.item.title : 'View Invoice'
        },
    },


    methods: {
        cancel() {
            this.open = false
        },

        resizeIframe(obj) {
            obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
        },

        getParseHtml() {
            request.get(`api/invoice/${this.item.id}/parse-template`)
                .then(({ data }) => {
                    this.parseTemplate = data.html
                })
        },

        downloadPDF() {
            request.get(`api/invoice/${this.item.id}/download`)
                .then(({ data }) => {
                    window.open(data.url, '_blank');
                })
        }
    }
}


<template>
  <div class="orders">
    <v-card min-height="500" flat>
      <v-card-title class="d-flex align-center justify-space-between">
        <BoardLinks
          no-orders
          class="ma-1"
          :no-incoming-orders="!can_view_incoming_orders"
        ></BoardLinks>
        <v-row dense align="center" justify="end" class="ma-1">
          <v-btn-toggle
            v-if="mdAndUp"
            tile
            dense
            v-model="filter.status"
            mandatory
          >
            <template v-for="(status, index) in order_statuses">
              <v-btn
                elevation="2"
                depressed
                color="white"
                class="pa-3 border-0 mr-1 text-none"
                active-class="active-button"
                :key="index"
                :value="status.value"
              >
                <span style="color: #223a6b; font-weight: 700; font-size: 17px">
                  {{ status.title | ucwords }}
                </span>
                <v-chip small outlined class="ml-2" v-if="status.counts < 0">
                  {{ status.counts }}
                </v-chip>
              </v-btn>
            </template>
          </v-btn-toggle>
          <cMenu
            block
            close-on-content-click
            btn-style="background:#233b6b;color:white;"
            v-else
            :label="filter.status | ucwords"
          >
            <v-list dense>
              <v-list-item
                class="primary--text"
                v-for="(status, index) in order_statuses"
                :key="index"
                @click="filter.status = status.value"
              >
                <v-list-item-title>{{ status.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </cMenu>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="ma-2 primary--text fw-700 fs-20">
        My Orders
      </v-card-subtitle>
      <v-card-text>
        <v-row dense align="center">
          <v-col md="12">
            <v-row v-if="orders.length > 0">
              <v-col
                md="12"
                xs="12"
                v-for="(item, index) in orders"
                :key="index"
              >
                <OrderList
                  :order="item"
                  @viewOrder="
                    $router.push({
                      name: 'view-order',
                      params: { id: item.id }
                    })
                  "
                  @cancelOrder="confirmCancelOrder(item)"
                  @deleteOrder="confirmDeleteOrder(item)"
                />
              </v-col>
              <v-col md="12" v-if="next_page_url">
                <v-btn
                  @click="getMoreOrders"
                  block
                  depressed
                  class="text-none my-2"
                  :loading="loading"
                >
                  View More
                </v-btn>
              </v-col>
            </v-row>
            <Empty
              v-else
              :min-height="500"
              icon="mdi-clipboard-alert"
              :headline="filter_result_message"
            ></Empty>
            <div v-if="loading" class="my-2 pa-1">
              <v-progress-linear :size="50" color="primary" indeterminate />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <DeleteDialog
      :open-dialog.sync="confirm_action_dialog"
      title="Confirmation required!"
      :text-content="confirm_text"
      @delete="
        confirm_action === 'cancel' ? handleCancelOrder() : handleDeleteOrder()
      "
    />
  </div>
</template>

<script>
import { offers_mixin } from '../mixin'
import request from '@/services/axios_instance'
import OrderList from '../components/OrderList.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import BoardLinks from '../components/BoardLinks.vue'

export default {
  name: 'Orders',
  mixins: [offers_mixin],
  components: {
    DeleteDialog,
    OrderList,
    BoardLinks
  },
  data: () => ({
    orders: [],
    order_statuses: [
      { title: 'All', counts: null, value: 'all' },
      { title: 'Ongoing', counts: null, value: 'ongoing' },
      { title: 'Cancelled', counts: null, value: 'cancelled' },
      { title: 'Completed', counts: null, value: 'completed' }
    ],
    order_categories: [],
    next_page_url: null,
    loading: false,
    filter: {
      category: 0,
      status: 'all'
    },
    confirm_text: '',
    active_order: null,
    confirm_action: null,
    confirm_action_dialog: false
  }),
  created() {
    // this.getOrderCategories();
    this.getOrders()
  },
  computed: {
    filter_result_message() {
      if (this.filter.status === 'all')
        return "You don't have paid orders yet. <br>Checkout your orders from Cart"
      return `No orders with ${this.filter.status.replace('_', ' ')} status!`
    }
  },
  watch: {
    'filter.status': {
      handler(val) {
        this.getOrders()
      }
    }
  },
  methods: {
    getOrderCategories() {
      request.get(`api/orders/categories`).then(({ data }) => {
        this.order_categories.push(...data)
      })
    },
    getOrders() {
      this.loading = true
      request
        .get(`api/orders?status=${this.filter.status}&paid=1`)
        .then(({ data }) => {
          this.orders = data.data
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading = false))
    },
    getMoreOrders() {
      this.loading = true
      if (this.next_page_url) {
        request
          .get(this.next_page_url)
          .then(({ data }) => {
            this.orders.push(...data.data)
            this.next_page_url = data.next_page_url
          })
          .finally(() => (this.loading = false))
      }
    },
    confirmCancelOrder(order) {
      this.active_order = order
      this.confirm_text = 'Are you sure you want to cancel this order?'
      this.confirm_action = 'cancel'
      this.confirm_action_dialog = true
    },
    confirmDeleteOrder(order) {
      this.active_order = order
      this.confirm_text = 'Are you sure you want to delete this order?'
      this.confirm_action = 'delete'
      this.confirm_action_dialog = true
    },
    handleCancelOrder() {
      request
        .put(`api/orders/${this.active_order.id}/cancel`)
        .then(({ data }) => {
          let index = this.orders.findIndex((i) => i.id === data.id)
          if (~index) {
            this.orders.splice(index, 1, data)
            this.appSnackbar('Successfully cancelled order')
          }
        })
        .finally(() => {
          this.active_order = null
          this.confirm_action = null
          this.confirm_action_dialog = false
        })
    },
    handleDeleteOrder() {
      request
        .delete(`api/orders/${this.active_order.id}/delete`)
        .then(({ data }) => {
          let index = this.orders.findIndex(
            (i) => i.id === this.active_order.id
          )
          if (~index) {
            this.orders.splice(index, 1)
            this.appSnackbar('Order successfully deleted')
          }
        })
        .finally(() => {
          this.active_order = null
          this.confirm_action = null
          this.confirm_action_dialog = false
        })
    }
  }
}
</script>

<style scoped>
.active-button {
  background: #223a6b !important;
}
.active-button span {
  color: white !important;
}
</style>
